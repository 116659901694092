<template>
    <div class="upload-progress-item">
        <transition-group name="list" mode="out-in">
            <div class="upload-status" v-if="!isUploadFinished" key="upload">
                <div class="controls">
                    <button type="button" class="play-pause-button button__clean" @click="togglePlayPause">
                        <svg-icon :icon="buttonIcon"/>
                    </button>
                </div>
                <div class="progress-bar-wrapper">
                    <span class="file-name">{{ name }}</span>
                    <span class="user-login">{{ login }}</span>
                    <span class="percentage">{{ progressInPercent ? progressInPercent.toFixed(1) : 0 }}%</span>
                    <div class="progress-bar" :style="{width: `${progressInPercent}%`}"></div>
                </div>
                <button @click="cancel" type="button" class="button__clean">
                    <svg-icon icon="IconTrash"/>
                </button>
            </div>

            <div class="unzip-status" v-if="isUploadFinished" key="unzip">
                <div class="status-indicator" :class="progressIndicatorClass"></div>
                <div class="status-text">
                    <div>
                        unzipping status: <strong>{{ unzippingStatus }}</strong>
                    </div>
                    <div>{{ name }} @ {{ login }}</div>

                </div>
                <button @click="cancel" type="button" class="button__clean" v-if="isUnzippingFinished">
                    <svg-icon icon="IconTimes" width="20" height="20"/>
                </button>
            </div>
        </transition-group>
    </div>
</template>

<script setup>
    import { ref, computed } from 'vue';
    import SvgIcon from '../SvgIcon';
    import useUploadStore from '@/store/uploadMission';

    const uploadStore = useUploadStore();
    const props = defineProps({
        progress: {
            type: Number,
            required: true
        },
        total: {
            type: Number,
            required: true
        },
        id: {
            type: String,
            required: true
        },
        name: {
            type: String,
            default: ''
        },
        login: {
            type: String,
            required: true
        },
        isUploadFinished: {
            type: Boolean,
            required: true
        },
        isUnzippingFinished: {
            type: Boolean,
            required: true
        },
        unzippingStatus: {
            type: String,
            default: ''
        }
    });

    const isFinished = ref(false);
    const isPaused = ref(false);

    const buttonIcon = computed(() => {
        const isUploading = !isFinished.value;

        return isUploading && isPaused.value ? 'IconPlay' : 'IconPause'
    });

    const progressInPercent = computed(() => props.progress / props.total * 100);
    const progressIndicatorClass = computed(() => {
        if (props.unzippingStatus === 'completed') {
            return 'finished'
        } else if (props.unzippingStatus === 'processing zip file') {
            return 'in-progress'
        } else if (props.unzippingStatus.includes('error')) {
            return 'error'
        }

        return '';
    });

    function togglePlayPause() {
        if (isPaused.value) {
            resume();
        } else {
            pause();
        }
    }

    function pause() {
        isPaused.value = true;
        uploadStore.pause(props.id);
    }

    function resume() {
        isPaused.value = false;
        uploadStore.resume(props.id);
    }

    function cancel() {
        uploadStore.cancel(props.id);
    }

</script>

<script>
    export default {
        name: 'upload-progress-item'
    }
</script>

<style lang='scss' scoped>
    @import './UploadProgressItem';
</style>
