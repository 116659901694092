import { defineStore } from 'pinia';
import { ref } from 'vue';
import { Storage } from 'aws-amplify';

const useUploadStore = defineStore('uploadMission', () => {
    const uploads = ref([]);
    const reUploadMissionId = ref(null);
    const instances = ref([]);

    function findInstance(id) {
        const index = instances.value.findIndex(({ id: instanceId }) => instanceId === id);

        return {
            index,
            instance: instances.value[index]?.instance
        };
    }

    function findUpload(id) {
        const index = uploads.value.findIndex(({ id: instanceId }) => instanceId === id);

        return {
            index,
            upload: uploads.value[index]
        }
    }

    function cancel(id) {
        const { index, instance } = findInstance(id);
        const { index: uploadIndex } = findUpload(id);

        console.log('index', index);

        if (index > -1) {
            Storage.cancel(instance);

            instances.value.splice(index, 1);
            uploads.value.splice(uploadIndex, 1);
        }
    }

    function pause(id) {
        const { index, instance } = findInstance(id);

        if (index > -1) {
            instance.pause();
        }
    }

    function resume(id) {
        const { index, instance } = findInstance(id);

        if (index > -1) {
            instance.resume();
        }
    }

    function updateInstance(id, instance) {
        instances.value.push({
            id,
            instance
        });
    }

    function updateProgress(id, progress, total) {
        const { index, upload } = findUpload(id);

        if (index > -1) {
            const newUpload = {
                ...upload,
                progress,
                total
            };

            uploads.value.splice(index, 1, newUpload);
        }
    }

    function setUploadFinished(id) {
        const { index, upload } = findUpload(id);

        if (index > -1) {
            const newUpload = {
                ...upload,
                isUploadFinished: true
            };

            uploads.value.splice(index, 1, newUpload);
        }
    }

    function setUnzipStatus(id, status) {
        const { index, upload } = findUpload(id);
        const isSuccess = status === 'completed';
        const isError = status.includes('error');

        if (index > -1) {
            const newUpload = {
                ...upload,
                unzippingStatus: status,
                ...(status && (isSuccess || isError) && { isUnzippingFinished: true })
            };

            uploads.value.splice(index, 1, newUpload);
        }
    }

    return {
        reUploadMissionId,
        uploads,
        cancel,
        updateInstance,
        updateProgress,
        setUploadFinished,
        setUnzipStatus,
        pause,
        resume
    }

});

export default useUploadStore;
