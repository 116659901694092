const en = {
    successLabel: 'Success',
    errorLabel: 'Error',
    pleaseWait: 'Please wait...',
    generalErrorMessage: 'Something went wrong, please try again later.',
    login: {
        welcome: 'Welcome',
        headerText: 'Login to your account',
        forgotPasswordSubheader: 'forgot?',
        forgotPasswordHeader: 'Reset your password',
        forgotPasswordButton: 'Forgot Password?',
        rememberMe: 'Remember me',
        login: 'Login now',
        backToSignIn: 'back to sign in',
        sendCode: 'Send Code',
        emailLabel: 'Email',
        passwordLabel: 'Password',
        resetPasswordLabel: 'Enter you email'
    },
    missions: {
        uploadMission: 'Upload mission',
        restoreDeletedMissions: 'Restore deleted missions',
        myMissions: 'My Missions',
        userMissions: 'Missions',
        sort: {
            mission: 'Mission',
            location: 'Location',
            coordinates: 'Coordinates',
            date: 'Date'
        },
        noMissions: 'Currently you have no missions available'
    },
    mission: {
        videos: 'Videos',
        trajectories: 'Trajectories',
        data: 'Data',
        frontCamera: 'Front Camera',
        bottomCamera: 'Bottom Camera'
    },
    nav: {
        users: 'Users',
        missions: 'Missions',
        dataViz: 'DataViz',
        threeDView: '3D View',
        signOut: 'Sign Out',
        admin: 'Admin'
    },
    chart: {
        altitude: 'Altitude',
        battery: 'Battery',
        temperature: 'Temperature'
    },
    map: {
        depth: 'DEPTH'
    },
    video: {
        loadingImage: 'LOADING IMAGE...',
        camera1: 'Camera 1',
        camera2: 'Camera 2',
    },
    missionsRemoveModal: {
        removeMission: 'Remove Mission? | Remove Missions?',
        removeMissionSubheader: 'Are you sure you want to delete mission: | Are you sure you want to delete missions:',
        yes: 'yes',
        no: 'no',
        missionRemoved: 'Mission removed | Missions removed',
        errorMessage: 'Something went wrong, please try again later.'
    },
    missionsRestoreModal: {
        restoreMissions: 'Restore missions?',
        restoreMissionsSubheader: 'Choose the missions you want to restore',
        restoreSelectedMissions: 'Restore selected missions',
        missionRestored: 'Mission restored | Missions Restored',
        noMissions: 'Currently there are no missions here'
    },
    missionsUploadMissionModal: {
        title: 'Upload Mission',
        uploadField: 'Choose File | Choose Files',
        overwriteWarning: '!!Warning You are trying to overwrite existing mission!!',
        submitButton: 'Start Upload'
    },
    users: {
        users: 'Users',
        companyName: 'Company name',
        user: 'User',
        emailAddress: 'E-mail address',
        addNewUser: '+ Add new user',
        deleteSelectedUsers: 'Delete selected users',
        filterByEmail: 'Filter by email'
    },
    createUser: {
        header: 'Add new user',
        subHeader: 'Please complete the profile',
        submitButton: 'Create user',
        companyName: 'Company name',
        firstName: 'First name',
        lastName: 'Last name',
        emailAddress: 'E-mail address',
        password: 'Temporary password',
        pleaseEnterInformation: 'Please enter you information',
        pleaseEnterYourPassword: 'Please enter your password',
        pleaseEnterYourEmail: 'Please enter your e-mail address',
        successMessage: 'User created',
        errorMessage: 'Error:',
        generate: 'Generate',
        adminPrivileges: 'Admin Privileges'

    },
    removeUser: {
        header: 'Remove user | Remove users',
        subHeader: 'Are you sure you want to delete this user: | Are you sure you want to delete these users:',
        successMessage: 'User removed | Users removed'
    }
}

export default en;
