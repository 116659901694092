import { API, Storage } from 'aws-amplify';
import { useRoute } from 'vue-router';
import { useUserStore } from '@/store/user';
import { storeToRefs } from 'pinia';

export default function useApi() {
    const route = useRoute();
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    function useGraphql(options) {
        return API.graphql({
            authMode: 'AMAZON_COGNITO_USER_POOLS',
            ...options
        })
    }

    function useGetStorage(key, options) {
        const config = {
            level: 'private',
            ...(user.value?.attributes?.sub && { identityId: user.value.attributes.sub }),
            ...(route?.params?.sub && { identityId: route?.params?.sub }),
            ...options
        };

        return Storage.get(key, config);
    }

    return {
        useGraphql,
        useGetStorage
    }
}
