<template>
    <div class="my-app">
        <router-view/>
        <Loader class="loader" v-if="loaderVisible" :data="loaderData"/>
        <notifications position="bottom right" :duration="10000"/>
        <upload-progress/>
    </div>
</template>

<script setup>
    import '@/assets/styles/main.scss';
    import { Auth, Hub } from 'aws-amplify';
    import Loader from '@/components/Loader';
    import { useLoaderStore } from '@/store/loader';
    import { useUserStore } from '@/store/user';
    import { onMounted } from 'vue';
    import { useRouter } from 'vue-router';
    import { storeToRefs } from 'pinia';
    import UploadProgress from '@/components/UploadProgress/UploadProgress';
    import useUploadStore from '@/store/uploadMission';

    const uploadStore = useUploadStore();
    const loaderStore = useLoaderStore();
    const userStore = useUserStore();
    const router = useRouter();
    const { data: loaderData, visible: loaderVisible } = storeToRefs(loaderStore);
    const { isVisible: isUploadProgressVisible } = storeToRefs(uploadStore);

    const listener = async (data) => {
        const user = data.payload.data;
        const isAdmin = user?.signInUserSession?.accessToken?.payload["cognito:groups"].includes('Admins');
        const currentUserInfo = await Auth.currentUserInfo();

        switch (data.payload.event) {
            case 'signIn':
                userStore.setUser({
                    ...user,
                    ...currentUserInfo.attributes,
                    isAdmin
                });

                if (data.payload.data.isAdmin) {
                    router.push({ name: 'users' });
                } else {
                    router.push({ name: 'missions' });
                }

                break;
            case 'signOut':
                userStore.setUser(null);
                router.push({ name: 'login' });

                break;

            case 'tokenRefresh':
                if (router?.currentRoute?.value?.redirectedFrom?.fullPath) {
                    router.replace(router.currentRoute.value.redirectedFrom.fullPath);
                } else if (isAdmin) {
                    router.push({ name: 'users' });
                } else {
                    router.push({ name: 'missions' });
                }

                break;
        }
    }

    Hub.listen('auth', listener);

    onMounted(async () => {
        try {
            const user = await Auth.currentAuthenticatedUser();
            const isAdmin = user?.signInUserSession?.accessToken?.payload["cognito:groups"].includes('Admins');

            userStore.setUser({
                ...user,
                isAdmin
            });
        } catch (error) {
            console.error(error.text);
        }
    })
</script>

<style lang="scss" scoped>
    .loader {
        position: fixed;
        z-index: 2;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
</style>
