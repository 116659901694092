<template>
    <div class="checkbox">
        <label class="checkbox__label" :class="`checkbox__label--${checkboxClass}`" :for="id">
            <slot name="before"></slot>

            <div class="checkbox__wrapper">
                <input :id="id" class="checkbox__native" type="checkbox" :name="name" :value="checked" @input="updateModelValue">
                <span class="checkbox__custom" :class="checkboxClass">
                    <span class="checkbox__icon" v-if="slots.icon">
                        <slot name="icon"></slot>
                    </span>
                </span>
            </div>
            <slot name="after"></slot>
        </label>
    </div>
</template>

<script setup>
    import { computed, ref, useSlots } from 'vue';
    import getUniqueId from '@/utils/getUniqueId';

    const slots = useSlots();
    const props = defineProps({
        type: {
            type: String,
            default: 'square'
        },
        checked: {
            type: Boolean,
            required: true
        },
        name: {
            type: String,
            default: ''
        }
    });

    const emit = defineEmits([ 'update:checked' ]);

    const id = ref(getUniqueId());

    const checkboxClass = computed(() => {
        if (props.type === 'square') {
            return 'checkbox__square';
        } else if (props.type === 'round') {
            return 'checkbox__round';
        }

        return '';
    });

    function updateModelValue(e) {
        emit('update:checked', !!e.target.checked);
    }

</script>
<script>
    export default {
        name: 'CheckboxComponent'
    }
</script>


<style lang="scss" scoped>
    @import './Checkbox';
</style>
