import { defineStore } from 'pinia';
import { getMissions } from '@/graphql/queries';
import useApi from '@/usables/useApi';
import { t } from '@/i18n/i18n';

const framesPerSecond = 1;
const speed = 1000 / framesPerSecond;

export const useMissionStore = defineStore('mission', {
    state: () => ({
        previousMissions: [],
        missionData: null,
        missionProgress: {},
        timerStart: 0,
        timerNow: 0,
        timerEnd: null,
        timerIsRunning: false,
        isTimerPaused: false,
        interval: 0,
        isFullScreenMode: false,
        currentFullScreenId: null,
        charts: [],
        pickedChartAttributes: [],
        chartAttributes: [
            { text: t('chart.altitude'), value: 'altitude', color: '#4da1ff', id: 1 },
            { text: t('chart.battery'), value: 'battery_voltage', color: '#fe5900', id: 2 },
            { text: t('chart.temperature'), value: 'temperature', color: '#e323ff', id: 3 }
        ],
        speed: {
            'x1': speed,
            'x2': speed / 2,
            'x3': speed / 3,
            'x4': speed / 4,
            'x5': speed / 5
        },
        currentSpeed: 'x1'
    }),
    actions: {
        changeMission(payload) {
            this.missionData = payload
        },
        setMissionProgress(id, payload) {
            this.missionProgress[id] = payload;
        },
        getMissionFromStore(missionId) {
            return this.previousMissions.find(({ id }) => id === missionId);
        },
        storeMission(mission) {
            this.previousMissions.push(mission);
        },
        fetchMissionData(payload) {
            const { useGraphql } = useApi();

            return useGraphql({
                query: getMissions,
                variables: {
                    id: payload
                }
            });
        },

        setTimerNow(payload) {
            this.timerNow = payload;
        },
        setTimerEnd(payload) {
            this.timerEnd = payload;
        },
        setTimerIsRunning(payload) {
            this.timerIsRunning = payload;
        },
        play() {
            this.timerIsRunning = true;
            this.isTimerPaused = false;
            this.interval = window.setInterval(() => {
                if (this.timerNow >= this.timerEnd) {
                    window.clearInterval(this.interval);
                    this.timerIsRunning = false;

                    return false;
                }

                this.timerNow = this.timerNow + 1;
            }, this.speed[this.currentSpeed]);
        },
        pause() {
            window.clearInterval(this.interval);
            this.timerIsRunning = true; // this is so that when mission is playing user can drag progress bar.
            this.isTimerPaused = true;
        },
        stop() {
            window.clearInterval(this.interval);
            this.timerIsRunning = false;
            this.isTimerPaused = false;
        },
        replay() {
            window.clearInterval(this.interval);
            this.timerIsRunning = false;
            this.isTimerPaused = false;
            this.timerNow = 0;
            this.play();
        },
        addPickedAttribute(payload) {
            this.pickedChartAttributes.push(payload)
        },
        removePickedAttribute(payload) {
            const list = [ ...this.pickedChartAttributes ];
            const index = list.findIndex((item) => item === payload);

            if (index > -1) {
                list.splice(index, 1);

                this.pickedChartAttributes = list;
            }
        },
        addChart() {
            const availableAttributes = this.chartAttributes.filter((attr) => !this.pickedChartAttributes.includes(attr.value));

            if (availableAttributes.length) {
                this.charts.push({
                    id: availableAttributes[0].id,
                    text: availableAttributes[0].text,
                    attribute: availableAttributes[0].value,
                    color: availableAttributes[0].color
                });
            }
        },
        removeChart(chartAttribute) {
            const index = this.charts.findIndex(({ attribute }) => attribute === chartAttribute);

            if (index > -1) {
                this.charts.splice(index, 1);
            }
        },
        replaceChart(newChartAttribute, oldChartAttribute) {
            const index = this.charts.findIndex(({ attribute }) => attribute === oldChartAttribute);
            const newItem = this.chartAttributes.find(({ value }) => value === newChartAttribute);

            if (index > -1 && newItem) {
                this.charts.splice(index, 1, {
                    ...newItem,
                    attribute: newItem.value
                });
            }
        },
        clearMission() {
            this.stop();
            this.timerNow = 0;
            this.charts = [];
            this.pickedChartAttributes = [];
            this.currentSpeed = 'x1';
            this.currentFullScreenId = null;
            this.isFullScreenMode = false;
        },
        toggleFullScreen(id) {
            this.isFullScreenMode = !this.isFullScreenMode;
            this.currentFullScreenId = this.isFullScreenMode ? id : null;
        },
        setFullScreenId(id) {
            this.currentFullScreenId = id;
        }
    }
})
