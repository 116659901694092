<template>
    <svg-icon class="spinner" v-if="isVisible" icon="IconCog"/>
</template>
<script setup>
    import SvgIcon from '@/components/SvgIcon';

    defineProps({
        isVisible: {
            type: Boolean,
            default: false
        }
    });
</script>
<script>
    export default {
        name: 'SpinnerComponent'
    }
</script>

<style lang="scss" scoped>
    .spinner {
        animation: spin 3s linear infinite;

        ::v-deep(path) {
            fill: $color-international-orange;
        }
    }
</style>
