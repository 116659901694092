import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import Amplify from 'aws-amplify';
import '@aws-amplify/ui-vue';
import aws_exports from './aws-exports';
import mitt from 'mitt';
import Notifications from '@kyvg/vue3-notification';
import { createPinia } from 'pinia';
import { i18n, t } from '@/i18n/i18n';
import { plugin, defaultConfig } from '@formkit/vue';
import { de, fr, pl, en } from '@formkit/i18n';

const emitter = mitt();
const app = createApp(App);

Amplify.configure(aws_exports);

app.provide('emitter', emitter);
app.provide('t', t);

// app.use(store)
app.use(router);
app.use(createPinia());
app.use(Notifications);
app.use(i18n);
app.use(plugin, defaultConfig({
    locales: { de, en, pl, fr }
}));

app.mount('#app');
