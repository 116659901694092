import { createI18n } from 'vue-i18n';
import messages from '@/i18n/messages';
import { inject } from 'vue';

export const i18n = createI18n({
    legacy: false,
    locale: 'en',
    fallbackLocale: 'en',
    messages
});

export function setLocale(payload) {
    const formsConfig = inject(Symbol.for('FormKitConfig'));

    i18n.global.locale.value = payload;
    formsConfig.locale = payload;
}

export const { global: { t } } = i18n;

