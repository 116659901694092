<template>
    <component :is="iconytype"></component>
</template>

<script setup>
    import { computed, defineProps, defineAsyncComponent } from 'vue';

    const IconLogo = defineAsyncComponent(() => import('../assets/icons/icon-logo.svg'));
    const IconData = defineAsyncComponent(() => import('../assets/icons/icon-data.svg'));
    const IconData2 = defineAsyncComponent(() => import('../assets/icons/icon-data2.svg'));
    const Icon3d = defineAsyncComponent(() => import('../assets/icons/icon-3d.svg'));
    const IconCrosshair = defineAsyncComponent(() => import('../assets/icons/icon-crosshair.svg'));
    const IconChevronRight = defineAsyncComponent(() => import('../assets/icons/icon-chevron-right.svg'));
    const IconChevronDown = defineAsyncComponent(() => import('../assets/icons/icon-chevron-down.svg'));
    const IconChevronDownBig = defineAsyncComponent(() => import('../assets/icons/icon-chevron-down-big.svg'));
    const IconArrowRight = defineAsyncComponent(() => import('../assets/icons/icon-arrow-right.svg'));
    const IconDownload = defineAsyncComponent(() => import('../assets/icons/icon-download.svg'));
    const IconEnter = defineAsyncComponent(() => import('../assets/icons/icon-enter.svg'));
    const IconCamera = defineAsyncComponent(() => import('../assets/icons/icon-camera.svg'));
    const IconFullScreen = defineAsyncComponent(() => import('../assets/icons/icon-full-screen.svg'));
    const IconFullScreenOff = defineAsyncComponent(() => import('../assets/icons/icon-full-screen-off.svg'));
    const IconPin = defineAsyncComponent(() => import('../assets/icons/icon-pin.svg'));
    const IconPlay = defineAsyncComponent(() => import('../assets/icons/icon-play.svg'));
    const IconPause = defineAsyncComponent(() => import('../assets/icons/icon-pause.svg'));
    const IconAdd = defineAsyncComponent(() => import('../assets/icons/icon-add.svg'));
    const IconRemove = defineAsyncComponent(() => import('../assets/icons/icon-remove.svg'));
    const IconCog = defineAsyncComponent(() => import('../assets/icons/icon-cog.svg'));
    const IconTrash = defineAsyncComponent(() => import('../assets/icons/icon-trash.svg'));
    const IconAdmin = defineAsyncComponent(() => import('../assets/icons/icon-admin.svg'));
    const IconRestore = defineAsyncComponent(() => import('../assets/icons/icon-restore.svg'));
    const IconCheckmark = defineAsyncComponent(() => import('../assets/icons/icon-checkmark.svg'));
    const IconTimes = defineAsyncComponent(() => import('../assets/icons/icon-times.svg'));
    const IconAddUser = defineAsyncComponent(() => import('../assets/icons/icon-add-user.svg'));
    const IconTrashRound = defineAsyncComponent(() => import('../assets/icons/icon-trash-round.svg'));
    const IconUserOutlined = defineAsyncComponent(() => import('../assets/icons/icon-user-outlined.svg'));
    const IconUpload = defineAsyncComponent(() => import('../assets/icons/icon-upload.svg'));

    const icons = {
        IconUserOutlined,
        IconTrashRound,
        IconAddUser,
        IconLogo,
        IconData,
        IconData2,
        Icon3d,
        IconCrosshair,
        IconChevronRight,
        IconChevronDown,
        IconArrowRight,
        IconChevronDownBig,
        IconDownload,
        IconEnter,
        IconCamera,
        IconFullScreen,
        IconFullScreenOff,
        IconPin,
        IconPlay,
        IconPause,
        IconAdd,
        IconRemove,
        IconTrash,
        IconCog,
        IconAdmin,
        IconRestore,
        IconCheckmark,
        IconTimes,
        IconUpload
    }

    const props = defineProps({
        icon: {
            type: String,
            required: true
        }
    });

    const iconytype = computed(() => {
        return icons[props.icon];
    });
</script>
