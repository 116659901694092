import { defineStore } from 'pinia';
import { ref } from 'vue';

export const useNavStore = defineStore('nav', () => {
    const isCollapsed = ref(false);
    const is3dVisible = ref(false);
    const isInMissionView = ref(false);
    const isUsersView = ref(false);

    const show = () => {
        isCollapsed.value = false;
    };

    const hide = () => {
        isCollapsed.value = true;
    };

    const toggle = () => {
        isCollapsed.value = !isCollapsed.value;
    }

    const setIs3dVisible = (payload) => {
        is3dVisible.value = payload;
    }

    const setIsInMissionView = (payload) => {
        isInMissionView.value = payload;
    }

    const setIsUsersView = (payload) => {
        isUsersView.value = payload;
    }

    return {
        isCollapsed,
        is3dVisible,
        isInMissionView,
        isUsersView,
        hide,
        show,
        toggle,
        setIs3dVisible,
        setIsInMissionView,
        setIsUsersView
    }
})
