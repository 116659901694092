<template>
    <div class="home">
        <Login/>
    </div>
</template>

<script setup>
    import Login from '@/components/Login/Login';
</script>
<script>
    export default {
        name: 'LoginViewComponent'
    }
</script>
