<template>
    <div class="upload-progress" :class="{'is-in-mission-view': isInMissionView}">
        <transition-group name="list">
            <upload-progress-item v-for="upload in uploads"
                                  :key="upload.id"
                                  :id="upload.id"
                                  :name="upload.name"
                                  :login="upload.login"
                                  :total="upload.total"
                                  :unzipping-status="upload.unzippingStatus"
                                  :is-unzipping-finished="upload.isUnzippingFinished"
                                  :is-upload-finished="upload.isUploadFinished"
                                  :progress="upload.progress"/>
        </transition-group>
    </div>
</template>

<script setup>
    import { useNavStore } from '@/store/nav';
    import { onMounted, onBeforeUnmount } from 'vue';
    import { storeToRefs } from 'pinia';
    import useUploadStore from '@/store/uploadMission';
    import UploadProgressItem from '@/components/UploadProgress/UploadProgressItem';

    const uploadStore = useUploadStore();
    const navStore = useNavStore();

    const { uploads } = storeToRefs(uploadStore);
    const { isInMissionView } = storeToRefs(navStore);

    const warnOfUploads = (e) => {
        if (uploads.value.length && uploads.value.some(instance => !instance.isUploadFinished)) {
            e.preventDefault();
            return confirm();
        }
    }

    onMounted(() => {
        window.onbeforeunload = warnOfUploads;
    });

    onBeforeUnmount(() => {
        window.onbeforeunload = null;
    });
</script>
<script>
    export default {
        name: 'upload-progress'
    }
</script>

<style lang='scss' scoped>
    @import './UploadProgress';
</style>
