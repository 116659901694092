import { defineStore } from 'pinia'

const types = {
    threed: {
        text: 'Loading 3d model and textures',
    },
    mission: {
        text: 'Loading your mission',
    },
    default: {
        text: 'Loading in progress..',
    },
    login: {
        text: 'Login in...'
    }
}

export const useLoaderStore = defineStore('loader', {
    state: () => ({
        visible: false,
        data: {},
    }),
    actions: {
        show(payload) {
            this.data = types[payload] ? types[payload] : types.default
            this.visible = true
        },
        hide() {
            this.data = {}
            this.visible = false
        }
    },
})
