<template>
    <div class="login" ref="loginElement">
        <div class="login__logo">
            <svg-icon icon="IconLogo"/>
        </div>
        <div class="login__form">

            <Authenticator :hide-sign-up="true"
                           :services="services"
                           :login-mechanisms="['email']"
                           :form-fields="formFields">

                <template v-slot:sign-in-header>
                    <div class="login__welcome-header">
                        <p class="login__subheader">{{ t('login.welcome') }}</p>
                        <h2 class="login__header-text">{{ t('login.headerText') }}</h2>
                    </div>
                </template>

                <template v-slot:reset-password-header>
                    <div class="login__welcome-header">
                        <p class="login__subheader">{{ t('login.forgotPasswordSubheader') }}</p>
                        <h2 class="login__header-text">{{ t('login.forgotPasswordHeader') }}</h2>
                    </div>
                </template>

                <template v-slot:sign-in-footer>
                    <div class="login__custom-footer">
                        <button class="viz amplify-button login__forgot-password-button" data-variation="link"
                                @click="auth.toResetPassword">
                            {{ t('login.forgotPasswordButton') }}
                        </button>

                        <div class="login__persist">
                            <Checkbox v-model:checked="isPersistLogin" type="round">
                                <template v-slot:after>{{ t('login.rememberMe') }}</template>
                            </Checkbox>
                        </div>

                        <button class="button__primary button__secondary--full-width"
                                @click="onSubmit">
                            <span class="button__background"></span>
                            <span class="button__text">{{ t('login.login') }}</span></button>
                    </div>
                </template>

                <template v-slot:reset-password-footer>
                    <button class="viz amplify-button" data-variation="link" type="button" @click="auth.toSignIn">
                        {{ t('login.backToSignIn') }}
                    </button>

                    <button class="button__primary button__secondary--full-width">
                        <span class="button__background"></span>
                        <span class="button__text">{{ t('login.sendCode') }}</span></button>
                </template>
            </Authenticator>


        </div>
    </div>
</template>

<script setup>
    import { ref, inject } from 'vue';
    import { Authenticator, useAuthenticator } from '@aws-amplify/ui-vue';
    import { Auth } from 'aws-amplify';
    import '@aws-amplify/ui-vue/styles.css';
    import SvgIcon from '@/components/SvgIcon';
    import Checkbox from '@/components/Checkbox/Checkbox';

    const auth = useAuthenticator();
    const t = inject('t');

    const loginElement = ref(null);
    const isPersistLogin = ref(false);
    const formFields = ref({
        signIn: {
            username: {
                labelHidden: false,
                placeholder: '',
                isRequired: true,
                label: t('login.emailLabel')
            },
            password: {
                hideLabel: false,
                required: true,
                placeholder: '',
                label: t('login.passwordLabel'),
            }
        },
        resetPassword: {
            username: {
                labelHidden: false,
                placeholder: '',
                isRequired: true,
                label: t('login.resetPasswordLabel')
            }
        }
    });

    const services = {
        async handleSignIn(formData) {
            let { username, password } = formData;
            username = username.toLowerCase();

            Auth.configure({
                storage: isPersistLogin.value ? localStorage : sessionStorage
            });

            try {
                return Auth.signIn({
                    username,
                    password,
                });
            } catch (e) {
                window.console.error(e);
            }
        },
    };

    function onSubmit() {
        const signInButton = loginElement.value.querySelector('.amplify-button[data-variation=primary]');
        signInButton.click();
    }

</script>

<script>
    export default {
        name: 'LoginComponent'
    }
</script>

<style lang="scss" scoped>
    @import './Login';
</style>
