<template>
    <div class="loader">
        <div class="inner">
            <spinner :is-visible="true"/>
            {{ data.text }}
        </div>
    </div>
</template>

<script setup>
    import { defineProps } from 'vue'
    import Spinner from '@/components/Spinner/Spinner';

    defineProps({
        showLoader: {
            type: Boolean,
            default: () => false,
        },
        data: {
            type: Object,
            default: () => ({})
        },
    })
</script>
<script>
    export default {
        name: 'LoaderComponent'
    }
</script>

<style lang="scss" scoped>
    .loader {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: gray;
        opacity: 0.8;
    }

    .inner {
        padding: 2rem 4rem;
        background-color: white;
        color: black;
        border-radius: 0.5rem;
        display: flex;
        align-content: center;
        align-items: center;
        gap: 20px;
    }
</style>
