const pl = {
    login: {
        welcome: 'Witaj'
    },
    nav: {
        users: 'Users',
        missions: 'Missions',
        dataViz: 'DataViz',
        threeDView: '3D View',
        signOut: 'Wyloguj sie',
        admin: 'Admin'
    },
    createUser: {
        header: 'Dodaj usera',
    }
}

export default pl;
