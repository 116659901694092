import { createRouter, createWebHistory } from 'vue-router';
import { useUserStore } from '@/store/user';
import { storeToRefs } from 'pinia';
import { useMissionStore } from '@/store/mission';
import { useLoaderStore } from '@/store/loader';
import { notify } from '@kyvg/vue3-notification';
import Login from '@/views/Login.vue'

const routes = [
    {
        path: '/login',
        name: 'login',
        component: Login,
        beforeEnter: (to, from, next) => {
            const userStore = useUserStore();
            const { user } = storeToRefs(userStore)

            if (user.value) {
                next(from.path || '/');
                // return false
            } else {
                next();
            }
        }
    },
    {
        path: '/users/',
        name: 'users',
        component: () => import(/* webpackChunkName: "users" */ '@/views/users.vue'),
        meta: { requiresAuth: true, isAdminOnly: true, isUserPath: false },
        redirect: { name: 'usersList' },
        children: [
            {
                path: '/users/',
                name: 'usersList',
                component: () => import(/* webpackChunkName: "usersList" */ '@/components/Users/Users')
            }, {
                meta: { isUserMissions: true },
                path: '/user/:sub/',
                name: 'userMissions',
                props: true,
                component: () => import(/* webpackChunkName: "missions" */ '@/views/dashboard/missions.vue')
            }
        ]
    },
    {
        path: '/',
        name: 'dashboard',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () => import(/* webpackChunkName: "dashboard" */ '@/views/dashboard.vue'),
        meta: { requiresAuth: true, isUserPath: true },
        children: [
            {
                path: '/',
                name: 'missions',
                props: true,
                component: () => import(/* webpackChunkName: "missions" */ '@/views/dashboard/missions.vue'),
                beforeEnter: async (to, from, next) => {
                    const userStore = useUserStore();
                    const { user } = storeToRefs(userStore);
                    const isAdmin = user?.value?.isAdmin;

                    if (isAdmin) {
                        next({ name: 'usersList' });
                    } else {
                        next();
                    }
                }
            },
            {
                path: '/mission/:id/:sub?',
                name: 'mission',
                component: () => import(/* webpackChunkName: "mission" */ '@/views/dashboard/mission.vue'),
                redirect: { name: 'data-viz' },
                beforeEnter: async (to, from, next) => {
                    const missionStore = useMissionStore();
                    const loaderStore = useLoaderStore();
                    const mission = missionStore.getMissionFromStore(to.params.id);

                    if (mission) {
                        missionStore.changeMission(mission);

                        return next();
                    }

                    loaderStore.show('mission');

                    try {
                        const response = await missionStore.fetchMissionData(to.params.id);
                        const { data: { getMissions: missionData } } = response;
                        const mission = {
                            ...missionData
                        }

                        missionStore.changeMission(mission);
                        missionStore.storeMission(mission);
                        next();
                    } catch (e) {
                        next(false);
                        window.console.error(e);
                        notify({
                            type: 'error',
                            title: 'Error',
                            text: 'Sorry... there was an error please try again later.'
                        })
                    } finally {
                        loaderStore.hide();
                    }
                },
                meta: { requiresMission: true, isUserMissions: true  },
                children: [
                    {
                        path: '/mission/:id/:sub?/data-viz',
                        name: 'data-viz',
                        component: () => import(/* webpackChunkName: "data-viz" */ '@/views/dashboard/mission/data-viz.vue'),
                    },
                    // {
                    //     path: '/mission/:id/data-viz-videos',
                    //     name: 'data-viz-videos',
                    //     component: () => import(/* webpackChunkName: "data-viz-videos" */ '@/views/dashboard/mission/data-viz-videos.vue'),
                    // },
                    // {
                    //     path: '/mission/:id/data-viz-path',
                    //     name: 'data-viz-path',
                    //     component: () => import(/* webpackChunkName: "data-viz-path" */ '@/views/dashboard/mission/data-viz-path.vue'),
                    // },
                    // {
                    //     path: '/mission/:id/data-viz-pictures',
                    //     name: 'data-viz-pictures',
                    //     component: () => import(/* webpackChunkName: "data-viz-pictures" */ '@/views/dashboard/mission/data-viz-pictures.vue'),
                    // },
                    // {
                    //     path: '/mission/:id/data-viz-graphs',
                    //     name: 'data-viz-graphs',
                    //     component: () => import(/* webpackChunkName: "data-viz-graphs" */ '@/views/dashboard/mission/data-viz-graphs.vue'),
                    // },
                    {
                        path: '/mission/:id/:sub?/3d-view',
                        name: '3d-view',
                        component: () => import(/* webpackChunkName: "three-d-view" */ '@/views/dashboard/mission/three-d-view.vue'),
                    },
                    // {
                    //     path: '/mission/:id/data-map',
                    //     name: 'data-map',
                    //     component: () => import(/* webpackChunkName: "data-map" */ '@/views/dashboard/mission/data-map.vue'),
                    // },
                ],
            }
        ],
    },
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach(async (to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    // const isUserPath = to.matched.some(record => record.meta.isUserPath);
    const isAdminOnly = to.matched.some(record => record.meta.isAdminOnly);
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);
    const isAdmin = user?.value?.isAdmin;

    if (!requiresAuth) {
        next();
        return
    }

    if (!user.value) {
        next('/login')
    } else if (isAdminOnly && !isAdmin) {
        next(from.path);
    }
        // else if (isAdmin && isUserPath) {
        //     console.log('FOR USER ONLY');
        //     next('/users');
    // }

    else {
        next();
    }
});

export default router
