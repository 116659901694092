/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const uploadFile = /* GraphQL */ `
  query UploadFile($url: String, $login: String) {
    uploadFile(url: $url, login: $login)
  }
`;
export const processZip = /* GraphQL */ `
  query ProcessZip($sub: String, $login: String, $missionId: String) {
    processZip(sub: $sub, login: $login, missionId: $missionId)
  }
`;
export const getMissions = /* GraphQL */ `
  query GetMissions($id: ID!) {
    getMissions(id: $id) {
      coordinates {
        lat
        lng
      }
      date
      status
      id
      isNew
      isDeleted
      location
      name
      storagePaths {
        bottomCameraImgPath
        frontCameraImgPath
        missionJsonFile
        objFile
        textureFile
        zipFile
      }
      sub
      owner
      createdAt
      updatedAt
    }
  }
`;
export const listMissions = /* GraphQL */ `
  query ListMissions(
    $filter: ModelMissionsFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMissions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        coordinates {
          lat
          lng
        }
        date
        status
        id
        isNew
        isDeleted
        location
        name
        storagePaths {
          bottomCameraImgPath
          frontCameraImgPath
          missionJsonFile
          objFile
          textureFile
          zipFile
        }
        sub
        owner
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUsers = /* GraphQL */ `
  query GetUsers($sub: ID!) {
    getUsers(sub: $sub) {
      sub
      familyName
      name
      companyName
      email
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $sub: ID
    $filter: ModelUsersFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listUsers(
      sub: $sub
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        sub
        familyName
        name
        companyName
        email
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
